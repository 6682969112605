<template>
  <el-table
    :data="data"
    stripe
    style="width: 100%"
    default-expand-all
    class="list-cook"
  >
    <el-table-column
      type="expand"
      width="50"
    >
      <template slot-scope="scope">
        <el-table
          :data="scope.row.dishes"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="STT"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="description"
            label="Tên món ăn"
            width="250"
          >
            <template slot-scope="props">
              <p>{{ props.row.dish.translations[0].summary }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="Loại đồ ăn"
            width="200"
            align="center"
          >
            <template slot-scope="props">
              {{ props.row.dish.dish_type | parseDishType }}
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="Kích thước"
            align="center"
          >
            <template slot-scope="props">
              <p>{{ props.row.dish.dish_size }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="Trạng thái"
          >
            <template slot-scope="props">
              <el-tag :type="parseDishSubscriptionStatus(props.row.kitchen_status).type">
                {{ parseDishSubscriptionStatus(props.row.kitchen_status).label }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label=""
            align="center"
            width="100"
          >
            <template slot-scope="props">
              <el-dropdown trigger="click" @command="(v) => handleUpdateDishSubcription(v, props.row)">
                <span class="el-dropdown-link btn-action">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="status in dishSubcriptionStatuses"
                    :key="status.key"
                    :command="status.value"
                    :disabled="props.row.kitchen_status === status.value"
                    :divided="!!status.divided"
                    :class="`text-${status.type}`"
                  >
                    {{ status.action }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <el-table-column
      type="index"
      label="STT"
      width="60"
    >
    </el-table-column>
    <el-table-column
      label="Mã đơn hàng"
      width="250"
    >
      <template slot-scope="scope">
        <el-button type="text">
          {{ scope.row.code }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      label="Tổng số món"
      width="200"
      align="center"
    >
      <template slot-scope="scope">
        {{ scope.row.receipt_lines.length }}
      </template>
    </el-table-column>
    <el-table-column
      label="Trạng thái ĐH"
      align="center"
      min-width="200"
    >
      <template slot-scope="scope">
        <el-tag :type="parseExecutedStatus(scope.row.executed_status).type">
          {{ parseExecutedStatus(scope.row.executed_status).label }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      label="Thao tác"
      width="100"
      align="center"
    >
      <template slot-scope="scope">
        <el-dropdown trigger="click" @command="updateReceipt">
          <span class="el-dropdown-link btn-action">
            <i class="el-icon-more"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="status in statuses"
              :key="status.key"
              :command="{ ...scope.row, executed_status: status.value }"
              :disabled="scope.row.executed_status === status.value"
              :divided="!!status.divided"
              :class="`text-${status.type}`"
            >
              {{ status.action }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { parseExecutedStatus, parsePaymentStatus, parseDishSubscriptionStatus } from '@/utils/filters'
import DISH from '@/data/dish'
import { updateDishSubscription } from '@/services/dish-subcription'

export default {
  name: 'ListCook',
  components: {},
  props: {
    data: Array
  },
  data() {
    return {
      visibleDetail: false,
      selectedReceipt: null,
      statuses: DISH.MANAGE_CART_STATUS.slice(1, 4).filter((s) => s.action),
      dishSubcriptionStatuses: DISH.SUBSCRIPTION_STATUS.filter((s) => s.action)
    }
  },
  methods: {
    parseExecutedStatus,
    parsePaymentStatus,
    parseDishSubscriptionStatus,
    updateReceipt(row) {
      const data = {
        id: row.id,
        customer_name: row.customer_name,
        email: row.email,
        address: row.address,
        phone: row.phone,
        description: row.description,
        is_private: row.is_private,
        total: row.total,
        subtotal: row.subtotal,
        status: row.status,
        executed_status: row.executed_status
      }
      this.$emit('update', data)
    },
    handleUpdateDishSubcription(value, row) {
      const data = {
        id: row.id,
        kitchen_status: value
      }
      updateDishSubscription(data).then(() => {
        this.$notify.success({
          title: 'Thông báo !',
          message: 'Đổi trạng thái thành công !'
        })
        row.kitchen_status = value
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.list-cook {
  .btn-action {
    font-size: 22px;
    cursor: pointer;
  }
  .expanded {
    background-color: #f4f4f5 !important;
  }
  .el-table__row--striped {
    background-color: #f4f4f5 !important;
    td {
      background: none !important;
    }
  }
  .el-table__expanded-cell[class*=cell] {
    padding: 20px 0px 20px 30px;
  }
}
</style>
